import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import teamlogo from "../images/Team1.png"

const PointsTable = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [loading, setLoading] = useState(true);
    const [groupAData, setGroupAData] = useState([]);
    const [groupBData, setGroupBData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch league name');
                }
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
                setError(error.message);
            }
        };

        const fetchPointsForGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/table/?group=${group}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.json();
            } catch (error) {
                console.error(`Error fetching points for group ${group}:`, error);
                setError(error.message);
                return [];
            }
        };

        const fetchPoints = async () => {
            try {
                setLoading(true);
                const [groupA, groupB] = await Promise.all([
                    fetchPointsForGroup('A'),
                    fetchPointsForGroup('B')
                ]);

                setGroupAData(groupA);
                setGroupBData(groupB);

                // Fetch league name after groups
                await fetchLeagueName();
            } catch (error) {
                console.error('Error in fetchPoints:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPoints();
    }, [leagueId]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error) {
        return <div className="flex justify-center items-center min-h-screen">Error: {error}</div>;
    }

    const sortedTeams = (teams) => {
        return teams.sort((a, b) => {
            // First, sort by points (descending)
            if (b.points !== a.points) {
                return b.points - a.points;
            }
            // If points are equal, sort by stone difference (descending)
            return b.stone_difference - a.stone_difference;
        });
    };

    const renderTable = (teams, groupName) => (
        <div className="mb-8">
            <h3 className="text-xl font-bold mb-2">Grupp {groupName}</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-white">
                    <thead className="bg-gray-800">
                        <tr>
                            <th className="bg-red px-2 py-2 text-left tracking-wider">#</th>
                            <th className="px-2 py-2 text-left tracking-wider">Lag</th>
                            <th className="px-2 py-2 text-left tracking-wider">
                                <span className="hidden md:inline">Spelade Matcher</span>
                                <span className="md:hidden">SM</span>
                            </th>
                            <th className="px-2 py-2 text-left tracking-wider">
                                <span className="hidden md:inline">Stenskillnad</span>
                                <span className="md:hidden">SK</span>
                            </th>
                            <th className="px-2 py-2 text-left tracking-wider">
                                <span className="hidden md:inline">Poäng</span>
                                <span className="md:hidden">P</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-500">
                        {sortedTeams(teams).map((team, index) => (
                            <tr key={team.id} className={`border-b hover:bg-gray-800 ${index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-600'}`}>
                                <td className="py-2 px-1">{index + 1}</td>
                                <td className="flex items-center px-2 py-3 whitespace-nowrap">
                                    <img className="w-5" src={teamlogo} alt={team.name} />
                                    <span className="ml-2 font-medium truncate max-w-[120px] md:max-w-none">
                                        {team.name}
                                    </span>
                                </td>
                                <td className="px-2 py-3 whitespace-nowrap text-center">{team.matches_played}</td>
                                <td className="px-2 py-3 whitespace-nowrap text-center">{team.stone_difference}</td>
                                <td className="px-2 py-3 whitespace-nowrap text-center">{team.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-10">
            <main className="flex-grow container mx-auto px-2 md:px-4 py-16 md:py-16">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">{leagueName}</h2>

                {/* Render Group A Table */}
                {renderTable(groupAData, 'A')}

                {/* Render Group B Table */}
                {renderTable(groupBData, 'B')}

                <p>SM* (Spelade matcher)</p>
                <p>SK* (Stenskillnad)</p>
            </main>

            <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
                <p>&copy; 2024 SweepScore</p>
            </footer>
        </div>
    );
};

export default PointsTable;