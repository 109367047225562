import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, User, Info } from 'lucide-react';

const LeagueDetail = () => {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/api/leagues/${id}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch league data');
        }
        return response.json();
      })
      .then(data => {
        setLeague(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching league:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (!league) {
    return <div className="flex justify-center items-center min-h-screen">League not found</div>;
  }

  const navigationLinks = [
    { to: `/league/${id}/matches`, text: 'Matcher' },
    { to: `/league/${id}/table`, text: 'Tabell' },
    { to: `/league/${id}/playoff`, text: 'Slutspel' },
    { to: `/league/${id}/teams`, text: 'Lag' }
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <main className="max-w-7xl mx-auto px-4 py-16 pt-16 space-y-16 flex-grow">
        <section className='pt-10'>
        <div className="w-full max-w-lg bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl">
          <div className="bg-gray-800 px-6 py-4">
            <h2 className="text-xl font-bold text-white">{league.name}</h2>
          </div>
  
          <div className="p-6 space-y-4">
            <div className="flex items-start space-x-3">
              <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-500">Arrangör</p>
                <p className="text-gray-900 font-medium">{league.organizer}</p>
              </div>
            </div>
  
            <div className="flex items-start space-x-3">
              <Info className="w-5 h-5 text-Gray-900 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-500">Information</p>
                <p className="text-gray-900">{league.info}</p>
              </div>
            </div>
  
            <div className="flex items-start space-x-3">
              <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-500">Datum</p>
                <p className="text-gray-900 font-medium">
                  {league.start_date} - {league.end_date}
                </p>
              </div>
            </div>
          </div>
        </div>
        </section>
      
  
      {/* Navigation Links - Only visible on mobile */}
      {isMobile && (
          <div className="mb-6 px-4">
            <ul className="flex flex-col gap-3">
              {navigationLinks.map((link) => (
                <li key={link.to} className="w-full">
                  <Link
                    to={link.to}
                    className="block w-full text-center py-3.5 bg-white text-gray-800 font-medium rounded-xl active:bg-gray-100 transition-colors shadow-sm border border-gray-200"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )} 
        </main>
        <footer className="bg-gray-800 text-white text-center p-4 w-full mt-auto">
        <p>&copy; 2024 SweepScore</p>
      </footer>
    </div>
  );
};

export default LeagueDetail;